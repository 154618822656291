import { disablePageScroll, enablePageScroll } from 'scroll-lock';

const $menuBtn = $('#navToggler');
const $menuLink = $('.nav-menu__item-link');
const $menuSmLink = $('.nav-menu__sm-item-link');
const $menuPrevBtn = $('.nav-menu__item-link--previous');
const $menuRootItem = $('.nav-menu__list-item--root');
const $backdrop = $('.page-backdrop');
const $searchBtn = $('#searchBtn');
const $searchForm = $('.form__wrapper');

let isLocked = false;
const dropBackdrop = () => {
	const $scrollableMenu = $('.nav-menu__wrapper');
	$menuBtn.toggleClass('site-header__nav-toggler--opened');
	// !isLocked ? disablePage2Scroll($scrollableMenu[0]) : enablePageScroll();
	$('body').toggleClass('menu-opened');
	isLocked = !isLocked;
};

$menuBtn.add($backdrop).on('click', dropBackdrop);

$menuLink.on('click', function(evt) {
	const $this = $(this);

	if (
		$this.closest('.nav-menu__list').hasClass('nav-menu__list--third') ||
		$this.closest('div.nav-menu__list').hasClass('nav-menu__list--third') ||
		$this.hasClass('nav-menu__item-link--root')
		) {
			return;
		}
		
		$this
		.toggleClass('nav-menu__item-link--opened')
		.closest('.nav-menu__list-item--root')
		.toggleClass('nav-menu__list--to-left');

		if ($(window).width() < 1024) {
			evt.preventDefault();
		}	
});

$menuPrevBtn.on('click', () => {
	$('.nav-menu__item-link--opened')
		.removeClass('nav-menu__item-link--opened')
		.closest('.nav-menu__list--to-left')
		.removeClass('nav-menu__list--to-left');
});

$menuSmLink.on('click', function(evt) {
	const $this = $(this);
	const $index = $this.parent().index();

	console.log($this);

	if ($(window).width() < 1024 && !$this.hasClass('nav-menu__sm-item-link--opened')) {
		evt.preventDefault();
	}

	$this
		.addClass('nav-menu__sm-item-link--opened')
		.parent()
		.siblings()
		.find('.nav-menu__sm-item-link')
		.removeClass('nav-menu__sm-item-link--opened');

	$('.nav-menu__list-item--root')
		.removeClass('nav-menu__list-item--root-opened')
		.eq($index)
		.addClass('nav-menu__list-item--root-opened');
});

// open menu on DESKTOP when hovering link
$menuRootItem
	.mouseenter(function() {
		$(this).find('ul').length ? $backdrop.addClass('page-backdrop--opened') : console.log('has no ul as child');
	})
	.mouseleave(() => {
		$backdrop.removeClass('page-backdrop--opened');
	});

// form opener
$searchBtn.on('click', (evt) => {
	evt.preventDefault();
	if ($('body').hasClass('menu-opened')) {
		dropBackdrop();
	}
	// $searchForm.toggleClass('form__wrapper--opened').find('[type="search"]').focus();
	$searchForm.toggleClass('form__wrapper--opened');
});


/* ------------------------------------------------------------------------------------------- */
const className = 'nav-menu__list-item--second-opened'
function revealMenu () {
	$(this).addClass(className).siblings().removeClass(className)
}
$('.nav-menu__list--second > .nav-menu__list-item--second:first-of-type').addClass(className)
$('.nav-menu__list--second > .nav-menu__list-item--second').mouseenter(revealMenu)
