export default function () {
  // Кнопка "Скрыть/показать" описание категории
  $('.category__show-btn').on('click', function (e) {
    $('.category__description').toggleClass('category__description--clip');
    $('.category__description-wrapper').toggleClass('category__description--show');
  });

  // Кнопка "Купить в 1 клик"
  $('.product__buy-click').on('click', function (e) {
    $('.btn-buy-one-click').toggleClass('btn-buy-one-click--active');
  });

  // Кнопка "Добавления товара в корзину"
  // $('.product__shop-cart').on('click', function (e) {
  //   $('.product__shop-cart').toggleClass('product__shop-cart--active');
  // });

  // Оставить комментарий к заказу
  $('.shop-cart__review').on('click', function (evt) {
    evt.preventDefault();
    
    $('.shop-cart__review-wrapper').toggleClass('shop-cart__review-wrapper--active');
  });

  // отправить форму заказа
  $('.btn-product.btn-approve--summary.btn-approve--modal').on('click', (evt) => {
    evt.preventDefault();

    $('.wa-step-confirm-section .wa-button.wa-submit-button.js-submit-order-button').click();
  })
}


