export default function () {
  $('.product__additionally-link').click(function () {
    $(this).siblings().removeClass('popup__wrapper--active');
    $(this).parents('.product__additionally-wrapper').find('.popup__wrapper').removeClass('popup__wrapper--active').eq($(this).index()).addClass('popup__wrapper--active');    
    $(this).toggleClass('popup__wrapper--active');
  });

  $('.popup__icon').click(function () {
    $(this).parents('.product__additionally-wrapper').find('.popup__wrapper').removeClass('popup__wrapper--active');
  });

  // popup shop-cart
  const showModal = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    
    const triggeredClass = 'popup-cart__backdrop--triggered';
    $('.popup-cart__backdrop').toggleClass(triggeredClass);
  };
  
  $('.popup-cart__close-btn').on('click', showModal);
  $('.popup-cart__backdrop, .btn-approve--modal').on('click', showModal);
}
