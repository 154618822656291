import Glide from "@glidejs/glide";

import brandsListSlider from './image-zoomer' 

if (document.querySelector("#hero")) {
  new Glide("#hero", {
    type: "carousel",
    startAt: 0,
    gap: 0,
    animationDuration: 1250,
    autoplay: 4000,
    hoverpause: true
    // keyboard: true
  }).mount();
}

if (document.querySelector("#brands")) {
  new Glide("#brands", {
    type: "carousel",
    startAt: 0,
    perView: 5,
    gap: 5,
    breakpoints: {
      320: {
        perView: 2,
        gap: 5
      },
      375: {
        perView: 3,
        gap: 5
      },
      414: {
        perView: 3,
        gap: 5,
      },
      768: {
        perView: 5
      }
    },
    animationDuration: 1250,
    autoplay: 5000,
    hoverpause: true
  }).mount();
}

if (document.querySelector("#recently-watched")) {
  const itemsCount = $("#recently-watched .glide__slide").length

  if (itemsCount > 2) {
    new Glide("#recently-watched", {
      type: "carousel",
      startAt: 0,
      perView: itemsCount === 3 ? 3 : 4,
      gap: 0,
      breakpoints: {
        320: {
          perView: 3,
          peak: {
            before: 0,
            after: 0
          }
        },
        768: {
          perView: itemsCount === 3 ? 3 : 4,
          peak: {
            before: 0,
            after: 0
          }
        }
      },
      animationDuration: 1000
    }).mount();
  }
  // setTimeout(
  //   () => {
  //     document.querySelector("#recently-watched").classList.add('tabs__content')
  //   },
  //   0
  // )
}

if (document.querySelector("#product-img")) {
  const productImagesSlider = new Glide("#product-img", {
    type: "carousel",
    startAt: 0,
    perView: 1,
    gap: 0,
    breakpoints: {
      320: {
        perView: 1,
        peak: {
          before: 0,
          after: 0
        }
      }
    }
  })
  
  productImagesSlider.mount();

//   productImagesSlider.on('run.after', function (evt) {
//     console.log('brandsListSlider', brandsListSlider)
//     console.log('evt.direction', evt)

//     $(brandsListSlider.selector).parent().css({opacity: 0, 'pointer-events': 'none'}).addClass('product__images-slider--opened')
//     brandsListSlider.go(`=${$('#product-img .glide__slide.glide__slide--active').index() - 1}`)
//     $(brandsListSlider.selector).parent().css({opacity: '', 'pointer-events': ''}).removeClass('product__images-slider--opened')
//   })
}

if (document.querySelector("#brandsList")) {
  const brandsList = new Glide("#brandsList", {
    type: "carousel",
    startAt: 0,
    perView: 1,
    gap: 0,
    breakpoints: {
      320: {
        perView: 1,
        peak: {
          before: 0,
          after: 0
        }
      },
      768: {
        perView: 1
      },
      1366: {
        perView: 1
      }
    }
  })

  window.innerWidth > 680 ? brandsList.mount() : null
}

if (document.querySelector(".offer-page__slider")) {
  const sliders = [...document.querySelectorAll('.offer-page__slider')]

  sliders.forEach((slider, idx) => {
    slider.id = `offerSlider${idx}`
    
    new Glide(`#${slider.id}`, {
      type: "carousel",
      startAt: 0,
      perView: 4,
      gap: 0,
      breakpoints: {
        320: {
          perView: 3,
          peak: {
            before: 0,
            after: 0
          }
        },
        768: {}
      },
      animationDuration: 1000
    }).mount();
  })
  
}

$('#product-buy-with .recently-watched .glide').each((idx, item) => {
  const itemsCount = $(item).find('.glide__slide').length
  const itemId = $(item).attr('id')

  $('#product-buy-with .tabs__list .tabs__item').first().addClass('tabs__item--current')

  if (itemsCount >= 2) {
    // setTimeout(() => {
      new Glide(`#${itemId}`, {
        type: "slider",
        startAt: 0,
        perView: itemsCount === 3 ? 3 : 4,
        gap: 0,
        peak: {
          before: 0,
          after: 0
        },
        breakpoints: {
          420: {
            perView: 2,
            peak: {
              before: 0,
              after: 0
            }
          },
          568: {
            perView: 3
          },
          // 768: {
          //   perView: itemsCount === 3 ? 3 : 4,
          //   peak: {
          //     before: 0,
          //     after: 0
          //   }
          // }
        },
        animationDuration: 1000
      }).mount();

      // }, 100 + idx)
    }
    $(`#product-buy-with #${itemId}`).parents('.recently-watched').addClass('tabs__content')

    $('#product-buy-with .recently-watched').first().addClass('tabs__content--current')
})
