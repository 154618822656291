import Glide from "@glidejs/glide";

let brandsListSlider;

function initZoomedProductImagesSlider() {
  if (document.querySelector("#zoomedProductImages")) {
    brandsListSlider = new Glide("#zoomedProductImages", {
      type: "carousel",
      startAt: 0,
      perView: 1,
      autoplay: false,
      gap: 0,
      breakpoints: {
        320: {
          perView: 1,
          peak: {
            before: 0,
            after: 0
          }
        }
      }
    })

    brandsListSlider.mount()
  }
}

function calculateScaleValue(elementNaturalHeight) {
  const viewportHeight = $(window).height()

  return `scale(${elementNaturalHeight / viewportHeight * 2})`
}

function calculateMouseTranslateY(mouseYPosition, imageHeight, viewportHeight) {
  let maxPercentage = 50 * (imageHeight / viewportHeight) / 2;
  let maxTopPercentage = maxPercentage + 1;
  let maxBottomPercentage = -maxPercentage - 1;
  let currentMousePositionPercentage = 100 * (.5 - mouseYPosition / viewportHeight);

  return currentMousePositionPercentage > maxTopPercentage ?
    currentMousePositionPercentage = maxTopPercentage : 
    currentMousePositionPercentage < maxBottomPercentage && (currentMousePositionPercentage = maxBottomPercentage), currentMousePositionPercentage
}

function initZoomSlider(){
  // const imageNaturalHeight = $('#zoomedProductImages .glide__slide img').prop('naturalHeight');
  let imageNaturalHeight = 800;
  
  const $zoomedSliderSlide = $('#zoomedProductImages .glide__slide');
  const $imageWrapper = $zoomedSliderSlide.find('.product-img__link-wrapper');
  const $image = $imageWrapper.find('img');
  let imageZoomed = false;
  
  $imageWrapper.on('click', function(evt) {
    evt.preventDefault();

    if (!imageZoomed) {
      // $(this).css('transform', calculateScaleValue(imageNaturalHeight));
      $(this).css('transform', calculateScaleValue(imageNaturalHeight));

      $image.on('mousemove', function(evt) {
        const translate3dStyles = `translate3d(0, ${calculateMouseTranslateY(evt.clientY, imageNaturalHeight, $(window).height())}%, 0`
        $(this).css('transform', translate3dStyles).css('cursor', 'move');
      })
    } else {
      $(this).css('transform', '');

      $image.off('mousemove').css('transform', 'translate3d(0px, 0px, 0px)').css('cursor', '')
    }
    imageZoomed = !imageZoomed;
  });
}

function initZoom() {
  initZoomSlider()

  $('.product__images-slider').addClass('product__images-slider--opened');
  initZoomedProductImagesSlider();
  $('.product__images-slider').removeClass('product__images-slider--opened');
  
  $('.product__img-wrapper .product__img-container').on('click', function(evt) {
    evt.preventDefault();
    
    $('.product__images-slider').addClass('product__images-slider--opened');
    
    $(document).on('keydown', function() {
      if (event.key == "Escape") {
        $('.product__images-slider').removeClass('product__images-slider--opened');

        $('#zoomedProductImages .glide__slide img').css('transform', '');
        $image.off('mousemove').css('transform', 'translate3d(0px, 0px, 0px)').css('cursor', '');

        $(document).off('keydown');
      }
    })
  })
}

$('.product__slider-close').on('click', function() {
  $('.product__images-slider').removeClass('product__images-slider--opened');
})

if ($(window).width() > 1023) {
  initZoom();
} else {
  $('.product__images-slider').addClass('product__images-slider--opened');
  initZoomedProductImagesSlider();
  $('.product__images-slider').removeClass('product__images-slider--opened');

  $('.product__img-wrapper .product__img-container').on('click', function(evt) {
    evt.preventDefault();
    
    $('.product__images-slider').addClass('product__images-slider--opened');

    $('#zoomedProductImages .glide__slide .product-img__link-wrapper').on('click', function(evt) {
      evt.preventDefault()
      
      const zoomedImage = `<div class="zoomed-image" style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; z-index: 501; overflow: scroll;">${$(this).parent().clone().children().css({'display': 'block', 'background-color': '#fff', 'height': '100%'}).addClass('product-img__link-wrapper--zoomed').parent().html()}</div>`
      
      console.log('$(this).clone()', zoomedImage);

      $('main').append(zoomedImage);

      $('.product-img__link-wrapper.product-img__link-wrapper--zoomed').on('click', function(evt) {
        evt.preventDefault();

        console.log($(this))

        $('.zoomed-image').remove();

        $('.product-img__link-wrapper.product-img__link-wrapper--zoomed').off('click');
      })
    })
  })
}

export default brandsListSlider;