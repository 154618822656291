function setBobyClass() {
  if (window.pickup === 'pickup') {
    $('html').addClass('pickup')
  } else {
    $('html').removeClass('pickup')
  }
}

try {
  setTimeout(() => {
    if ($('[data-id="pickup"]').hasClass('is-active')) {
      window.pickup = 'pickup'
      setBobyClass()
    }
  }, 5000);

  $('body').on('click', function(evt) {
    const dataId = $(evt.target).closest('.wa-type-wrapper').data('id')
    
    if (dataId === 'pickup' || dataId === 'todoor' || dataId === 'post') {
      console.log('if####', dataId)
      window.pickup = dataId
      setBobyClass()
    }
  });
} catch (err) {
  console.log(err)
}

try {
  $('.product-filter__filter-wrapper .wrapper #checkbox').on('change', function() {
    $('.product-filter__submit-wrapper').toggleClass('product-filter__submit-wrapper--visible')
  })
} catch (err) {
  console.log(err)
}