function initTabs() {
  $(".tabs__item").click(function (evt) {
    if (!$(this).hasClass("for-customers__item")) {
      evt.preventDefault();
    }

    $(this).siblings().removeClass("tabs__item--current");
    $(this)
      .parents(".tabs__wrapper")
      .find(".tabs__content")
      .removeClass("tabs__content--current")
      .eq($(this).index())
      .addClass("tabs__content--current");
    $(this).addClass("tabs__item--current");
  });

  $(".tabs__sub-item").click(function (evt) {
    evt.preventDefault();

    $(this).siblings().removeClass("tabs__sub-item--current");
    $(this)
      .parents(".tabs__sub-wrapper")
      .find(".tabs__sub-content")
      .removeClass("tabs__sub-content--current")
      .eq($(this).index())
      .addClass("tabs__sub-content--current");
    $(this).addClass("tabs__sub-item--current");
  });
}

initTabs();

document.addEventListener("initTabs", initTabs);

// let event = new Event("initTabs", { bubbles: true });
// document.dispatchEvent(event)

// Tabs-filter

$(document).ready(function () {
  const filterTabs = (btnClass, parentDiv) => {
    const $btns = $(btnClass).click(function (event) {
      event.preventDefault();
      if (this.id === "all") {
        $(`${parentDiv} > div`).fadeIn(0);
      } else {
        const $el = $(`.${this.id}`).fadeIn(0);
        $(`${parentDiv} > div`).not($el).hide();
      }
      $btns.removeClass("active");
      $(this).addClass("active");
    });
  };

  filterTabs(".tabs-filter__btn-cur", "#parent");
  filterTabs(".for-customers__loyalty-tab", "#loyalty-tabs");
  filterTabs(".s-shipping-methods-tab", "#shipping-methods");
  filterTabs(".s-payment-tab", "#payment-methods");

  $(".for-customers__loyalty-tab:first").click();
  $(".s-shipping-methods-tab:first").click();
  $(".s-payment-tab:first").click();
});
