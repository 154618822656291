$('.product-filter__toggler').on('click', function(evt) {
	evt.stopPropagation();

	const $this = $(this);

	$this.toggleClass('product-filter__toggler--opened');
});

$('.product-filter__all-toggler').on('click', function(evt) {
	evt.preventDefault();

	const $this = $(this);
	const $index = $this.index();

	$('.product-filter__filters-fields')
		.eq($index)
		.find('.product-filter__filters-block')
		.addClass('product-filter__filters-block--opened');
});

$('.product-filter__close-toggler').on('click', function(evt) {
	evt.stopPropagation();

	const $this = $(this);
	$('.product-filter__submit-wrapper').removeClass('product-filter__submit-wrapper--visible');

	if ($this.hasClass('product-filter__close-toggler--category-btn')) {
		return;
	}

	$this.closest('.product-filter__options-wrapper').siblings('button').removeClass('product-filter__toggler--opened');
});

$('.product-filter__close-toggler--category-btn').on('click', function(evt) {
	evt.preventDefault();
	
	$(this).parents('.product-filter__filters-block--opened').removeClass('product-filter__filters-block--opened');
});

$('.product-filter__options-wrapper').on('click', (evt) => {
	$('.product-filter__submit-wrapper').addClass('product-filter__submit-wrapper--visible');
});

$('.product-filter__submit-btn').on('click', (evt) => {
	// evt.preventDefault();
	
	$('.product-filter__submit-wrapper').removeClass('product-filter__submit-wrapper--visible');
	$('#productFilter .product-filter__toggler--opened').removeClass('product-filter__toggler--opened');
	$('#productFilter .product-filter__filters-block--opened').removeClass('product-filter__filters-block--opened');
});
