import "./lib";
import "./utils";

// hide menu on scroll
let prevScrollPos = 0;
let currentScrollTop = 0;
const $navbar = $(".site-header__container");

$(window).scroll(function () {
  const a = $(window).scrollTop();
  const b = $navbar.height();

  currentScrollTop = a;

  if (prevScrollPos < currentScrollTop && a > b + b) {
    $navbar.addClass("site-header__container--scroll-up");
  } else if (prevScrollPos > currentScrollTop && !(a <= b)) {
    $navbar.removeClass("site-header__container--scroll-up");
  }
  prevScrollPos = currentScrollTop;
});

// Кнопка "Скрыть/показать" о компании
$(".about-us__show-btn").on("click", function (e) {
  $(".about-us__description").toggleClass("about-us__description--clip");
  $(".about-us__description-wrapper").toggleClass(
    "about-us__description--show"
  );
});

// Копирование промокода

const copyButton = document.getElementById("copy-button");
const promoCode = document.getElementById("promo-code");

function showTooltip() {
  const tooltip = document.createElement("div");
  tooltip.textContent = "Скопировано";
  tooltip.classList.add("tooltip");

  document.body.appendChild(tooltip);

  setTimeout(() => {
    tooltip.remove();
  }, 2000);
}

if (copyButton) {
  // Если copyButton существует
  copyButton.addEventListener("click", () => {
    const textToCopy = promoCode.textContent;
    
    // Проверяем, не пустая ли переменная textToCopy
    if (textToCopy.trim() !== '') {
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          showTooltip();
        })
        .catch((error) => {
          console.error("Ошибка при копировании промокода:", error);
        });
    } else {
      console.error("Промокод пуст");
    }
  });
} else {
  console.error("Кнопка копирования не найдена");
}

const widthLimit = 525;
const text1 = document.querySelector(".product-label__list--1");
const text2 = document.querySelector(".product-label__list--2");

document.documentElement.style.setProperty("--widthLimit", `${widthLimit}px`);

if (text1 && text1.clientWidth > widthLimit) {
  text1.classList.add("limited");
}

if (text2 && text2.clientWidth > widthLimit) {
  text2.classList.add("limited");
}