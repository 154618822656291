// http://auxiliary.github.io/rater/
import raterJs from 'rater-js';

if (document.querySelector("#rater")) {
  raterJs({
    element: document.querySelector("#rater"),
    rateCallback: function rateCallback(rating, done) {
      this.setRating(rating);
      done();
    },

    showToolTip: true,
    max: 5,
    starSize: 20,
    ratingText: '{rating}/{maxRating}',
  });
}

function initNewRatingStars() {
  const customerRates = [...document.querySelectorAll(".rater-voted:not(.star-rating)")]

  customerRates.forEach(rate => {
    raterJs({
      element: rate,
      showToolTip: true,
      max: 5,
      rating: parseInt(rate.dataset.rating || 0),
      starSize: 20,
      ratingText: '{rating}/{maxRating}',
      readOnly: true    
    });
  })
}

if (document.querySelectorAll(".rater-voted").length) {
  initNewRatingStars()
}

document.addEventListener("initNewRatingStars", initNewRatingStars)
