export default function () {
  let intervalId = 0;
  const $scrollButton = document.querySelector('.scroll-to-top');

  $(".scroll-to-top").hide();

  $(window).scroll(function () {
    if ($(this).scrollTop() > 100) {
      $('.scroll-to-top').fadeIn();
    } else {
      $('.scroll-to-top').fadeOut();
    }
  });

  function scrollStep() {
    if (window.pageYOffset === 0) {
      clearInterval(intervalId);
    }
    window.scroll(0, window.pageYOffset - 50);
  }

  function scrollToTop() {
    intervalId = setInterval(scrollStep, 16.66);
  }

  $scrollButton.addEventListener('click', scrollToTop);
}
