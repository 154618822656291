// import tabs from './tabs';
import './tabs';
// import heroSlider from './glideSlider';
import './glideSlider';
import goTop from './goTop';
import './mainMenu';
import productInfoCollapse from './productInfoCollapse';
import ratingStars from './ratingStars';
import clickElements from './clickElements';
import popup from './popup';
import './image-zoomer';

// heroSlider();
// tabs();
goTop();
productInfoCollapse();
clickElements();
popup();
